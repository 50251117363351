@import '../variables.scss';

.headerForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px dashed;
    padding: 1rem;
    border-radius: 8px;
    .submitButton {
        align-self: flex-end;
    }

   
      
} .errorBanner {
    background-color: #ffcccc;
    color: #d8000c;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }